.main-img {
  background: url(../../assets/images/admin_panel_bg.png);
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-align {
  height: 100vh;
  display: flex;
  align-items: center;
}

.logo-content {
  position: absolute;
  top: 10px;
  top: 150px;
  left: 40px;
}

.logo-parent {
  position: absolute;
  bottom: 0;
  height: 125px;
  width: auto;
}

.logo-content h1 {
  font-size: 60px;
}

.login-card {
  right: 40px !important;
}

.copyright-content {
  position: absolute;
  bottom: 0;
  right: 40px;
  color: white;
}

.Varada-copyright,
.Varada-copyright:hover {
  color: white !important;
  cursor: pointer;
}

.usericon {
  font-size: 75px;
  height: 70px;
}

.icon-size {
  font-size: 18px;
}

.login-card-shadow {
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63) !important;
}

.fadeIn {
  /* adds animation during Ad-rotation */
  -webkit-animation: fadeIn 200ms ease-in-out;
  animation: fadeIn 200ms ease-in-out;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

/*
   * If you use sticky Ads: Add a smooth transition, z-index
   */
.stickyElx {
  transition: all 0.5s;
  z-index: 1;
}

/* ***********Dashboard********** */
.product-icon-font {
  font-size: 30px !important;
}

.top-header {
  height: 60px !important;
}

.topnav {
  margin-top: 60px !important;
}

/* *********Breadcrumb***** */
.last-login-font {
  font-size: 13px !important;
  font-weight: 600;
  color: #000000 !important;
}

.tablebg {
  background: #eff2f7 !important;
}

.footer-content {
  font-size: 13px !important;
}

.footer-content a,
.footer-content a:hover {
  color: #495057;
}

.whatsapp-font {
  font-size: 34px !important;
  color: white;
}

.whatsapp-parent {
  background: #008000b0;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.support-parent {
  background: #1a1819;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.suppport-font {
  color: #1a1819;
}

.table-bg {
  background-color: #777f80 !important;
}

.fadePage {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  bottom: 0;
  height: 100vh;
  display: block;
}

.categoryCard.focuscard,
.focuscard {
  z-index: 99999;
  background: #fff;
  position: relative;
}

.focusinput {
  height: 56px !important;
}

.card-row-bottom {
  border-bottom: none !important;
}

.cardpadding-ob {
  padding-top: 32px !important;
}

.categoryCard.focuscardvertical {
  z-index: 99999;
  background: #fff;
  position: relative;
  padding-top: 5px !important;
}
.text-black {
  color: #000;
}
